import React from 'react';
import { Grid, List, Typography, ListItemText, Paper, ListItem } from '@material-ui/core';

import Header from '../../components/header';
import Layout from '../../components/layout';
import SEO from '../../components/seo';

import { useStyles } from '../../utils/tu-instituto/styles';
import { useGlobalStyles } from '../../utils/styles';
import { valores } from '../../utils/tu-instituto/constants';

const Mision = () => {
  const classes = useStyles();
  const globalClasses = useGlobalStyles();

  return (
    <Layout>
      <SEO title="Misión" />

      <div className={globalClasses.container}>
        <Header title="Filosofía institucional"></Header>

        <Grid container spacing={5} className={classes.info}>
          <Grid item xs={12} container spacing={2}>
            <Grid item xs={12}>
              <Typography variant="h3" component="h2">Visión</Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography variant="body1">Ser una institución que garantice la seguridad social a los trabajadores al servicio del estado y sus municipios, a través de la estabilidad financiera, y el manejo honesto de los recursos, recuperando la atención integral de las prestaciones médicas, económicas, sociales, culturales y deportivas, fortaleciendo con infraestructura y procesos de vanguardia.</Typography>
            </Grid>
          </Grid>

          <Grid item xs={12} container spacing={2}>
            <Grid item xs={12}>
              <Typography variant="h3" component="h2">Misión</Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography variant="body1">Otorgar de manera integral servicios médicos y prestaciones socioeconómicas a los trabajadores del Estado y sus municipios.</Typography>
            </Grid>
          </Grid>

          {/* <Grid item xs={12} container spacing={2}>
            <Grid item xs={12}>
              <Typography variant="h3" component="h2">Objetivo general</Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography variant="body1">Garantizar la estabilidad y seguridad financiera del Instituto recuperando las políticas de seguridad social, encaminadas a fortalecer el sistema de prestaciones médicas, económicas, sociales, culturales y deportivas.</Typography>
            </Grid>
          </Grid> */}

          <Grid item xs={12} container spacing={2}>
            <Grid item xs={12}>
              <Typography variant="h3" component="h2">Valores y principios</Typography>
            </Grid>
            <Grid item xs={12}>
              <Paper>
                <List disablePadding>
                  {valores.map((valor, index) => (
                    <ListItem key={index} divider>
                      <ListItemText primary={valor.name} secondary={valor.description} />
                    </ListItem>
                  ))}
                </List>
              </Paper>
            </Grid>
          </Grid>
        </Grid>
      </div>
    </Layout>
  );
};

export default Mision;